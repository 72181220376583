import axios, { AxiosRequestConfig } from 'axios';

import {
  RegistrationPayloadProps,
  AuthProps,
  UserProfileProps,
  UserProfileDataProps,
  LeadsListProps,
  CampaignDataProps,
  MessageTemplatePayloadProps,
  MessageTemplateProps,
  EmailExamplesProps,
  AllChatProps,
  ChatByIdProps,
  ChatHighlightsProps,
  ConversationFiltersProps,
  LeadsTagsProps,
  StatisticsProps,
  ChatByCampaignIdProps,
  PhoneNumberProps,
  GenerateMessageByCampaignByIdProps,
  AgentPayloadProps,
  AgentProps,
  FacebookIntegrationProps,
  FacebookAdsProps,
  FacebookAdIdProps,
  InstagramAdsProps,
  AnalyticsStatsDataObjectProps,
  DashboardStatisticsProps,
  FacebookAdCampaignsProps,
  FacebookLeadsProps,
} from '../types/_models';
import { API_URL } from '../../helpers/envProcessor';
import api from '../../services/api';

//user
export const REGISTER_USER_URL = `${API_URL}/register`;
export const LOGIN_URL = `${API_URL}/login`;
export const REFRESH_TOKEN = `${API_URL}/auth-token-refresh`;
export const FORGOT_PASSWORD_URL = `${API_URL}/forgot-password`;
export const RESET_PASSWORD_URL = `${API_URL}/reset-password`;
export const GET_PROFILE_URL = `${API_URL}/profiles`;
export const UPDATE_PROFILE_URL = `${API_URL}/profiles`;
//leads
export const POST_HUBSPOT = `${API_URL}/import/hubspot`;
export const IMPORT_LEADS_FROM_FILE = `${API_URL}/import/file`;
export const GET_LEADS = `${API_URL}/leads`;
export const ADD_LEADS_TO_CAMPAIGN = `${API_URL}/campaigns/leads`;
export const GET_LEADS_BY_CAMPAIGN_ID = `${API_URL}/campaigns/leads`;
//campaign
export const POST_CAMPAIGN = `${API_URL}/campaigns`;
export const GET_MESSAGES_BY_TEMPLATE = `${API_URL}/mt/type`;
export const CREATE_MESSAGE_TEMPLATE = `${API_URL}/mt/type`;
export const GET_CAMPAIGNS = `${API_URL}/campaigns/me`;
export const GENERATE_CAMPAIGN_MESSAGE = `${API_URL}/campaigns`;
export const SEND_MESSAGE_TO_OPEN_AI = `${API_URL}/oa`;
export const DELETE_CAMPAIGN_BY_ID = `${API_URL}/campaigns`;
export const STOP_CAMPAIGN_BY_ID = `${API_URL}/campaigns`;
//email examples
export const SEND_EMAIL_EXAMPLE = `${API_URL}/email-examples`;
export const SEND_EMAIL = `${API_URL}/campaigns/emails`;
//chats
export const GET_ALL_CHATS = `${API_URL}/chats/me`;
export const GET_CHAT_BY_ID = `${API_URL}/chats`;
export const CHANGE_CHAT_CONTROL_BY_ID = `${API_URL}/chats`;
export const GET_CHAT_SUMMARY_BY_ID = `${API_URL}/chats`;
export const GET_CHATS_BY_CAMPAIGN_ID = `${API_URL}/chats/campaign`;
//tags
export const GET_ALL_TAGS = `${API_URL}/tags/users`;
export const ADD_TAG_BY_LEAD_ID = `${API_URL}/tags/add-to-lead`;
export const REMOVE_TAG_BY_LEAD_ID = `${API_URL}/tags/remove-from-lead`;
export const CREATE_TAG_BY_LEAD_ID = `${API_URL}/tags/users`;
export const REMOVE_TAG_BY_ID = `${API_URL}/tags/remove`;
//avatar
export const UPLOAD_AVATAR = `${API_URL}/profiles`;
export const REMOVE_AVATAR = `${API_URL}/profiles`;
//statistics
export const GET_STATISTICS = `${API_URL}/stats/conversationalLeads/users`;
export const EXPORT_STATISTICS = `${API_URL}/export/stats/campaign`;
export const GET_ANALYTICS_STATS = `${API_URL}/stats/analytics/users`;
export const GET_DASHBOARD_STATS = `${API_URL}/stats/dashboard/users`;
export const EXPORT_ANALYTICS_STATS = `${API_URL}/export/stats/users`;

//phone numbers
export const GET_PHONE_NUMBERS = `${API_URL}/phone-numbers`;
export const LINK_PHONE_NUMBER_TO_CAMPAIGN = `${API_URL}/phone-numbers`;

//agents
export const GET_AGENTS = `${API_URL}/agents/users/me`;
export const CREATE_AGENT = `${API_URL}/agents`;
export const UPDATE_AGENT = `${API_URL}/agents`;
export const DELETE_AGENT = `${API_URL}/agents`;

//integrations
export const FACEBOOK_INTEGRATION = `${API_URL}/integration/facebook/login`;
export const INSTAGRAM_INTEGRATION = `${API_URL}/integration/facebook/instagram`;
export const GET_FACEBOOK_AD_ACCOUNTS = `${API_URL}/integration/facebook/adAccounts`;
export const GET_FACEBOOK_AD_CAMPAIGNS = `${API_URL}/integration/facebook/adAccounts`;
export const CONNECT_FACEBOOK_TO_CAMPAIGN = `${API_URL}/integration/facebook/connect/ads`;
export const CONNECT_INSTAGRAM_TO_CAMPAIGN = `${API_URL}/integration/facebook/connect/instagram`;
export const GET_FACEBOOK_AD_ID = `${API_URL}/integration/facebook/adCampaigns`;
export const GET_FACEBOOK_LEADS = `${API_URL}/campaigns/facebookLeads/me`;

export function registerUser(registrationProps: RegistrationPayloadProps) {
  return axios.post<AuthProps>(REGISTER_USER_URL, {
    email: registrationProps.email,
    password: registrationProps.password,
    firstName: registrationProps.firstName,
    lastName: registrationProps.lastName,
    country: registrationProps.country,
    phoneNumber: registrationProps.phoneNumber,
  });
}

export function getAuthToken(email: string, password: string) {
  return axios.post<AuthProps>(LOGIN_URL, {
    email,
    password,
  });
}

export function refreshToken(userId: string, refreshToken: string) {
  return axios.post<AuthProps>(REFRESH_TOKEN, { userId, refreshToken });
}

export function forgotPassword(email: string) {
  return axios.post<boolean>(FORGOT_PASSWORD_URL, {
    email,
  });
}

export function resetPassword(token: string, password: string) {
  return axios.post<boolean>(RESET_PASSWORD_URL, {
    token,
    password,
  });
}

export function getUser(userId: string, token: string) {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.get<UserProfileProps>(`${GET_PROFILE_URL}/${userId}`, config);
}

export function updateUser(
  token: string,
  userId: string,
  updateUserPayload: UserProfileProps
) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.patch<UserProfileDataProps>(
    `${UPDATE_PROFILE_URL}/${userId}`,
    updateUserPayload,
    { headers }
  );
}

export function connectToHubspot(hubSpotAuthCode: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return api.post(POST_HUBSPOT, { code: hubSpotAuthCode }, config);
}

export function getLeads(userId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return api.get<LeadsListProps[]>(`${GET_LEADS}/${userId}`, config);
}

export function importLeadsFromFile(file: File) {
  const formData = new FormData();
  formData.append('import', file);

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return api.post(IMPORT_LEADS_FROM_FILE, formData, config);
}

export function createCampaign(campaignDataPayload: CampaignDataProps) {
  const headers = {
    'Content-Type': 'application/json',
  };
  return api.post<CampaignDataProps>(`${POST_CAMPAIGN}`, campaignDataPayload, {
    headers,
  });
}

export function updateCampaign(campaignDataPayload: CampaignDataProps) {
  const headers = {
    'Content-Type': 'application/json',
  };
  return api.patch<CampaignDataProps>(
    `${POST_CAMPAIGN}/${campaignDataPayload.id}`,
    campaignDataPayload,
    {
      headers,
    }
  );
}

export function getMessageTemplatesByType(messageTemplate: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return api.get<MessageTemplateProps[]>(
    `${GET_MESSAGES_BY_TEMPLATE}/${messageTemplate}`,
    config
  );
}

export function createMessageTemplate(
  messageType: string,
  messageTemplatePayload: MessageTemplatePayloadProps
) {
  const headers = {
    'Content-Type': 'application/json',
  };
  return api.post<MessageTemplateProps>(
    `${CREATE_MESSAGE_TEMPLATE}/${messageType}`,
    messageTemplatePayload,
    {
      headers,
    }
  );
}

export function getCampaigns() {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json', // Ensure the content type is JSON
    },
  };

  // Send the request with the body object
  return api.get<CampaignDataProps[]>(GET_CAMPAIGNS, config);
}

export function deleteCampaignById(campaignId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json', // Ensure the content type is JSON
    },
  };

  // Send the request with the body object
  return api.delete(`${DELETE_CAMPAIGN_BY_ID}/${campaignId}`, config);
}

export function stopCampaignById(campaignId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json', // Ensure the content type is JSON
    },
  };

  // Send the request with the body object
  return api.post(`${STOP_CAMPAIGN_BY_ID}/${campaignId}/stop`, config);
}

export function getCampaignById(campaignId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return api.get<CampaignDataProps>(`${GET_CAMPAIGNS}/${campaignId}`, config);
}

export function generateMessageByCampaignById(campaignId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return api.get<GenerateMessageByCampaignByIdProps>(
    `${GENERATE_CAMPAIGN_MESSAGE}/${campaignId}/initialMessageGenerate`,
    config
  );
}

export function addLeadToCampaignById(campaignId: string, leadIds: string[]) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json', // Ensure the content type is JSON
    },
  };
  return api.post(
    `${ADD_LEADS_TO_CAMPAIGN}/${campaignId}/add`,
    leadIds, // Directly pass the array
    config
  );
}

export function getLeadsByCampaignId(campaignId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json', // Ensure the content type is JSON
    },
  };

  // Send the request with the body object
  return api.get(`${GET_LEADS_BY_CAMPAIGN_ID}/${campaignId}`, config);
}

export function sendEmailExample(userId: string, emailText: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json', // Ensure the content type is JSON
    },
  };

  // Create the request body as an object
  const requestBody = {
    userId,
    emailText,
  };

  // Send the request with the body object
  return api.post(SEND_EMAIL_EXAMPLE, requestBody, config);
}

export function getEmailExample(userId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json', // Ensure the content type is JSON
    },
  };

  // Send the request with the body object
  return api.get<EmailExamplesProps[]>(
    `${SEND_EMAIL_EXAMPLE}/user/${userId}`,
    config
  );
}

export function changeEmailExample(
  emailId: string, // Only the emailId is needed for the path parameter
  emailText: string
) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json', // Ensure the content type is JSON
    },
  };

  // Create the request body as an object
  const requestBody = {
    emailText,
  };

  // Send the request with the emailId in the URL and the body object
  return api.put(`${SEND_EMAIL_EXAMPLE}/${emailId}`, requestBody, config);
}

/*export function sendEmailMessage(
  token: string,
  type: string,
  email: string,
  campaignId: string
) {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json', // Ensure the content type is JSON
    },
  };

  const requestBody = {
    type,
    email,
    password: 'password',
    campaignId,
    isPrimary: false,
  };

  // Send the request with the body object
  return axios.post(SEND_EMAIL, requestBody, config);
}*/

/*export function getEmailMessages(token: string, campaignId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json', // Ensure the content type is JSON
    },
  };

  // Send the request with the body object
  return axios.get<EmailMessageProps[]>(`${SEND_EMAIL}/${campaignId}`, config);
}*/

export function getAllChats(filters: ConversationFiltersProps) {
  const queryParameters = [];

  if (filters.campaignName) {
    queryParameters.push(`filterBy=name&value=${filters.campaignName}`);
  }
  if (filters.words) {
    queryParameters.push(`filterBy=words&value=${filters.words}`);
  }
  if (filters.conversation) {
    queryParameters.push(`filterBy=title&value=${filters.conversation}`);
  }
  if (filters.userName) {
    queryParameters.push(`filterBy=username&value=${filters.userName}`);
  }
  if (filters.platforms && filters.platforms.length > 0) {
    filters.platforms.forEach((platform) => {
      queryParameters.push(`filterBy=platform&value=${platform}`);
    });
  }

  const queryString =
    queryParameters.length > 0 ? `?${queryParameters.join('&')}` : '';

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.get<AllChatProps[]>(`${GET_ALL_CHATS}${queryString}`, config);
}

export function getChatsByCampaignId(
  filters: ConversationFiltersProps,
  campaignId: string
) {
  const queryParameters = [];

  if (filters.campaignName) {
    queryParameters.push(`filterBy=name&value=${filters.campaignName}`);
  }
  if (filters.words) {
    queryParameters.push(`filterBy=words&value=${filters.words}`);
  }
  if (filters.conversation) {
    queryParameters.push(`filterBy=title&value=${filters.conversation}`);
  }
  if (filters.userName) {
    queryParameters.push(`filterBy=username&value=${filters.userName}`);
  }
  if (filters.platforms && filters.platforms.length > 0) {
    filters.platforms.forEach((platform) => {
      queryParameters.push(`filterBy=platform&value=${platform}`);
    });
  }

  const queryString =
    queryParameters.length > 0 ? `?${queryParameters.join('&')}` : '';

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.get<ChatByCampaignIdProps>(
    `${GET_CHATS_BY_CAMPAIGN_ID}/${campaignId}${queryString}`,
    config
  );
}

export function getChatById(chatId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.get<ChatByIdProps>(`${GET_CHAT_BY_ID}/${chatId}`, config);
}

export function changeChatControl(chatId: string, control: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const requestBody = {
    control,
  };

  return api.post(
    `${CHANGE_CHAT_CONTROL_BY_ID}/${chatId}/change-control`,
    requestBody,
    config
  );
}

export function getChatSummary(chatId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.get<ChatHighlightsProps>(
    `${GET_CHAT_SUMMARY_BY_ID}/${chatId}/summary`,
    config
  );
}

export function addTagByLeadId(leadId: string, tagName: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const requestBody = {
    leadId,
    tagName,
  };

  return api.post<LeadsTagsProps>(ADD_TAG_BY_LEAD_ID, requestBody, config);
}

export function createTagByLeadId(leadId: string, name: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const requestBody = {
    name,
  };

  return api.post<LeadsTagsProps>(
    `${CREATE_TAG_BY_LEAD_ID}/${leadId}`,
    requestBody,
    config
  );
}

export function removeTagByLeadId(leadId: string, tagName: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      leadId,
      tagName,
    },
  };

  return api.delete(REMOVE_TAG_BY_LEAD_ID, config);
}

export function getAllTags(userId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.get(`${GET_ALL_TAGS}/${userId}`, config);
}

export function removeTagById(tagId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      tagId,
    },
  };

  return api.delete(`${REMOVE_TAG_BY_ID}/${tagId}`, config);
}

export function uploadAvatar(userId: string, file: File) {
  const formData = new FormData();
  formData.append('avatar', file);

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return api.post(`${UPLOAD_AVATAR}/${userId}/avatar`, formData, config);
}

export function removeAvatar(userId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      userId,
    },
  };

  return api.delete(`${REMOVE_AVATAR}/${userId}/avatar`, config);
}

export function getStatisticsById(userId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.get<StatisticsProps[]>(`${GET_STATISTICS}/${userId}`, config);
}

export function exportStatisticsByCampaignId(campaignId: string, type: string) {
  const config: AxiosRequestConfig = {
    responseType: 'blob',
  };

  return api.get(
    `${EXPORT_STATISTICS}/${campaignId}/conversations?type=${type}`,
    config
  );
}

export function getPhoneNumbers() {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.get<PhoneNumberProps[]>(GET_PHONE_NUMBERS, config);
}

export function linkPhoneNumberToCampaignId(
  number: string,
  campaignId: string
) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const requestBody = {
    number,
    campaignId,
  };

  return api.post(LINK_PHONE_NUMBER_TO_CAMPAIGN, requestBody, config);
}

export function getAgents() {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.get<AgentProps[]>(GET_AGENTS, config);
}

export function createAgent(createAgentPayload: AgentPayloadProps) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.post<AgentProps>(CREATE_AGENT, createAgentPayload, config);
}

export function updateAgent(
  agentId: string,
  updateAgentPayload: AgentPayloadProps
) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.put<AgentProps>(
    `${UPDATE_AGENT}/${agentId}`,
    updateAgentPayload,
    config
  );
}

export function deleteAgent(agentId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.delete<AgentProps[]>(`${DELETE_AGENT}/${agentId}`, config);
}

export function facebookIntegration(campaignId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: { campaignId }, // Pass campaignId as a query parameter
  };

  return api.get<FacebookIntegrationProps>(FACEBOOK_INTEGRATION, config);
}

export function getFacebookAdAccounts(campaignId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: { campaignId }, // Pass campaignId as a query parameter
  };

  return api.get<FacebookAdsProps[]>(GET_FACEBOOK_AD_ACCOUNTS, config);
}

export function instagramIntegration(campaignId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: { campaignId }, // Pass campaignId as a query parameter
  };

  return api.get<InstagramAdsProps[]>(INSTAGRAM_INTEGRATION, config);
}

export function connectFacebookAddToCampaign(
  campaignId: string,
  adAccountId: string,
  adId: string
) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.post(
    CONNECT_FACEBOOK_TO_CAMPAIGN,
    { campaignId, adAccountId, adId },
    config
  );
}

export function getFacebookAdId(adCampaignId: string, campaignId: string) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.get<FacebookAdIdProps[]>(
    `${GET_FACEBOOK_AD_ID}/${adCampaignId}/campaigns/${campaignId}/ads`,
    config
  );
}

export function connectInstagramAddToCampaign(
  campaignId: string,
  accountId: string
) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.post(
    CONNECT_INSTAGRAM_TO_CAMPAIGN,
    { campaignId, accountId },
    config
  );
}

export function getFacebookAdCampaigns(
  adAccountId: string,
  campaignId: string
) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.get<FacebookAdCampaignsProps[]>(
    `${GET_FACEBOOK_AD_CAMPAIGNS}/${adAccountId}/campaigns/${campaignId}/adCampaigns`,
    config
  );
}

export function getAnalyticsStats(
  userId: string,
  analyticsQueryString: string
) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.get<AnalyticsStatsDataObjectProps>(
    `${GET_ANALYTICS_STATS}/${userId}${analyticsQueryString}`,
    config
  );
}

export function getDashboardStats(
  userId: string,
  dashboardQueryString: string
) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.get<DashboardStatisticsProps>(
    `${GET_DASHBOARD_STATS}/${userId}${dashboardQueryString}`,
    config
  );
}

export function exportStatsByUserId(
  userId: string,
  statsType: string,
  analyticsQueryString: string
) {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.get(
    `${EXPORT_ANALYTICS_STATS}/${userId}/${statsType}${analyticsQueryString}`,
    config
  );
}

export function getFacebookLeads() {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api.get<FacebookLeadsProps[]>(GET_FACEBOOK_LEADS, config);
}
